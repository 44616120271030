import React, { useCallback } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';
import Score from './Score.js';
import likeIcon from '../icons/like_icon.svg';
import shareIcon from '../icons/social_share_icon.svg';
import commentIcon from '../icons/comment_icon.svg';
import connectIcon from '../icons/friend_icon.svg';
import UserPhoto from '../components/UserPhoto.js';


import firebase from 'firebase';




const StyledFeedCard = styled(Flex)`
    border-radius: 0px;
  box-shadow: 0 5px 14px 0 #9a5500, 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
  border-image-slice: 1;
  
  background-origin: border-box;
  background-clip: content-box, border-box;

  & a{color:inherit; text-decoration:none;}

  margin: 24px 0 19px;
  padding: 0;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
`;

const UserName = styled(Box)`
   font-size:16px;
   color:#000;
   font-weight:500;
`;

const Caption = styled(Box)`
   font-size:16px;
   padding:16px;
   text-align:left;
`;

const Location = styled(Box)`
   font-size:12px;
   color:#666;
   
`;

const Options = styled(Box)`
   display:flex;
   align-items:center;
   font-size:24px;
   color:#000;
`;

const FeedImage = styled(Box)`
   & img{width:100%;}
`;

const StyledUserPhoto = styled(UserPhoto)`
   & .badge{width:24px; left:-8px}
`;

const PostAction = styled(Box)`
   & img{
       filter: invert(0);
       transition: all .3s ease-in-out;
   }
   &.toggled img{
       filter: invert(1) !important;
   }
   transition: all .3s ease-in-out;
`;


export default function CameraFeedItem(props){

   const addReaction = (post_id, callback, type) =>{
      let db = firebase.firestore();
      let docRef = db.collection('posts').doc(post_id.toString());
      
      docRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({
         type:"like", 
         count: 1, 
         post_id: post_id, 
         user_id: firebase.auth().currentUser.uid,
        })},{merge: true}).then(docRef=>{

         let userRef = db.collection('users').doc(firebase.auth().currentUser.uid);
       
         userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"like", count: 1, post_id: post_id})},{merge: true}).then((result)=>{

               if(callback){
                  callback({type:"like", count: 1, post_id: post_id},result);
               }

         }).catch(error=>{});
      }).catch(error=>{});
}

const removeReaction = (post_id, callback, type) =>{
   let db = firebase.firestore();
   let docRef = db.collection('posts').doc(post_id.toString());
   
   docRef.update({reactions: firebase.firestore.FieldValue.arraRemove({
      type:"like", 
      count: 1, 
      post_id: post_id, 
      user_id: firebase.auth().currentUser.uid,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(firebase.auth().currentUser.uid);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"like", count: 1, post_id: post_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"like", count: -1, post_id: post_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

const connectWithUser = (user_id, callback) =>{
   let db = firebase.firestore();
   let docRef = db.collection('users').doc(firebase.auth().currentUser.uid);
   
   docRef.update({connections: firebase.firestore.FieldValue.arraUnion({
      type:"worker", 
      approved: false,
      user_id: user_id,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(user_id);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayUnion({type:"worker", user_id: user_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"like", count: -1, user_id: user_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

const removeUserConnection = (user_id, callback) =>{
   let db = firebase.firestore();
   let docRef = db.collection('users').doc(firebase.auth().currentUser.uid);
   
   docRef.update({connections: firebase.firestore.FieldValue.arraRemove({
      type:"worker", 
      approved: false,
      user_id: user_id,
     })},{merge: true}).then(docRef=>{

      let userRef = db.collection('users').doc(user_id);
    
      userRef.update({reactions: firebase.firestore.FieldValue.arrayRemove({type:"worker", user_id: user_id})},{merge: true}).then((result)=>{

            if(callback){
               callback({type:"connection", count: -1, user_id: user_id},result);
            }

      }).catch(error=>{});
   }).catch(error=>{});
}

   let likeCount = props.reactions ? props.reactions.filter(r=> r.type === "like").length : 0;

   let shareCount = props.reactions ? props.reactions.filter(r=> r.type === "share").length : 0;

   let commentCount = props.comments ? props.comments.filter(r=> r.approved === true).length : 0;

   let connectionsCount = props.connections ? props.reactions.filter(r=> r.type === "like").length : 0;

  
    return (<StyledFeedCard className="camera-feed-card" flexDirection="column" style={{marginBottom:"16px"}} {...props}>
    
  <Flex flexDirection="column">
             <Flex padding="16px" justifyContent="space-between">
                {props.user ? <StyledUserPhoto user={props.user} style={{width:"48px",height:"48px"}} /> : null}
                <Flex  padding="0 16px" alignItems="center" textAlign="left" flexGrow="1">
                   <Box>
                   {props.user ? (<UserName>{props.user.first_name+" "+props.user.last_name}</UserName>) : null}
                   {props.user ? (<Location>{props.user.location}</Location>) : null}
                  </Box>
                </Flex>
                <Options>...</Options>
             </Flex>

             <Caption>
                {props.caption}
             </Caption>

             <FeedImage>
                <img src={props.image}/>
             </FeedImage>
             <Flex justifyContent="space-between" padding="16px">
                <PostAction key="like-button" className={props.reactions && props.reactions.find(r=>r.user_id === firebase.auth().currentUser.uid) ? "toggled" : ''} onClick={(e)=>{
                   let likeButton = e.currentTarget;
                   likeButton.classList.toggle("toggled");
                   
                   addReaction(props.post_id, (reaction, docRef)=>{
                      console.log(docRef, likeButton,  likeCount, reaction);
                      likeButton.querySelector("div").textContent = likeCount + reaction.count;
                   }, "like");
                }}><img src={likeIcon}/>
                  <Box key="like-count">{likeCount}</Box>
                </PostAction>

                <PostAction key="share-button" ><img src={shareIcon}/>
                  <Box key="like-count">{shareCount}</Box>
                </PostAction>

                <PostAction key="comment-button" onClick={(e)=>{
                   let likeButton = e.currentTarget;
                   likeButton.classList.toggle("toggled");
                   
                  props.setCommentModalOpen(true);
                }}><img src={commentIcon}/>
                <Box key="like-count">{commentCount}</Box>
                </PostAction>

                <PostAction key="connect-button" ><img src={connectIcon}/>
                  <Box key="like-count">{connectionsCount}</Box>
                </PostAction>
             </Flex>
         </Flex>
    </StyledFeedCard>);
}

