import React from 'react';
import styled from 'styled-components';
import {Flex, Box} from 'rebass';

const ShareMenu = styled(Flex)``;
const ShareButton = styled(Box)``;

export default function(props){
    return  <>{props.ShareToggled ? <ShareMenu>
        <ShareButton><i class="fab fa-linkedin"></i></ShareButton>
        <ShareButton><i class="fab fa-linkedin"></i></ShareButton>
        <ShareButton><i class="fab fa-linkedin"></i></ShareButton>
        <ShareButton><i class="fab fa-linkedin"></i></ShareButton>
        <ShareButton><i class="fab fa-linkedin"></i></ShareButton>
    </ShareMenu> : null}</>
}