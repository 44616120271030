import React, { useState, useEffect } from 'react';
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer
} from "@react-firebase/auth";
import firebase from 'firebase';

//Libraries
import _ from 'lodash';

//Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";


//Components : Shouldn't need to import these any atomic components here, modularize.
import './App.css';
import Button from './components/Button.js';
import {ThemeProvider} from 'styled-components';
import Nav from './components/Nav.js';
import Menu from './components/Menu.js';
import Score from './components/Score.js';
import {Box, Flex} from 'rebass';
import { Label, Checkbox, Radio as Radiobutton } from '@rebass/forms'
//import icon from './hh_icon_black.png';
import { ModalProvider } from 'styled-react-modal';
import Modal from './components/Modal2.js';
import Confirm from './components/Confirm.js';
import Snackbar from './components/Snackbar.js';
import {Image, Location, ModalTitle, StyledLabel, StyledTextarea, DeviceFrame, Header} from "./components/components.js";

//Views

import Home from './views/Home.js';
import MenuView from './views/Menu.js';
import Registration from './views/Registration/Registration.js';
import JobsFeed from './views/Jobs.js';
import Feed from './views/Feed.js';
import CreatePost from './views/CreatePost';
import ChatList from './views/ChatList.js';
import Me from './views/Me.js';
import JobDetail from './views/JobDetail.js';
import WorkerDetail from './views/WorkerDetail.js';
import SignIn from './views/SignIn.js';
import Filters from './views/Filters.js';
import Search from './views/Search.js';
import Loading from './views/Loading.js';
import PostJob from './views/PostJob';
import ChatConversation from './views/ChatConversation';
import UserPhoto from './components/UserPhoto';

//Sample Data
import Jobs from './jobs.json';
import ApplicationsTable from './job_applications.json';

//Images
import SampleUserPhoto from './john_doe.png';
import profesionalImage from './employee_image.png';
import employerImage from './employer_image.png';

//Themes 
const theme = {
  yellowGradient: "linear-gradient(127deg, rgb(255, 238, 0) 12%, rgb(246, 191, 0) 76%)",
  generalPadding: "16px",
  lightYellow: "#f6bf00",
  darkYellow: "#ffc600",
}

//API Keys
//This should be a secret in the CI integration
const firebaseConfig  = {
  apiKey: "AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw",
  authDomain: "hiring-hall-cb7d9.firebaseapp.com",
  projectId: "hiring-hall-cb7d9",
  storageBucket: "hiring-hall-cb7d9.appspot.com",
  messagingSenderId: "193779993036",
  appId: "1:193779993036:web:ac2ad95cbbd2950a687569",
  measurementId: "G-S1K3C00VLZ"
};

const guestUser = {
  type: "worker",
  name: "Guest User",
  first_name: "Guest",
  last_name: "User",
  middle_name: "",
  username: "guest",
  email: "johndoe@hiringhall.com",
  phone: "555-121-51212",
  trade: "Carpenter",
  level: "Master",
  membership: "elite-manager",
  union_id: null,
  location: "New York",
  work_area: "New York",
  image: SampleUserPhoto,
  rate: 35,
  rating: 5,
  licenses: [1, 2, 3],
  certificates: [1, 2, 3],
  experience: [1,2,3]
}

const dummyUser = {
  type: "worker",
  name: "John Perez",
  first_name: "John",
  last_name: "Perez",
  middle_name: "Fitzgerald",
  username: "johndoe",
  email: "johndoe@hiringhall.com",
  phone: "555-121-51212",
  trade: "Carpenter",
  level: "Master",
  membership: "free",
  union_id: null,
  location: "New York",
  work_area: "New York",
  image: SampleUserPhoto,
  rate: 35,
  rating: 5,
  licenses: [1, 2, 3],
  certificates: [1, 2, 3],
  experience: [1,2,3]
}

const favoriteArray = [1, 3, 10];

//Experience can be an array with 3 numbers: Title/Role ID + Level ID + Rate, cross referenced in other tables: [0,3,75] =  Master Carpenter $75/h
const experience = [
  {id:0, title:"Carpenter", level:"Master", rate: 75},
  {id:1, name:"CDA", description:"Test"},
  {id:2, name:"TRE", description:"Test"}
]

const titles = [
 "Carpenter", "Mason", "Clown"
]

const levels = [
  "Novice", "Amateur", "Pro", "Master", "Yoda", "Goku"
]

const sampleConvs = [
  {date:"Monday", title: "Master Carpenter", name:"Turner", message:"Your profile looks like a good fit. Contact us at 555-555-5555"}
];

function App() {

  //Application data, should be on a store or contextProvider
  const[user, setUser] = useState(null);
  const[companies, setCompanies] = useState([]);
  const[users, setUsers] = useState([]);
  const[jobs, setJobs] = useState([]);
  const [Applications, updateApplications] = useState([]);
  const [posts, updatePosts] = useState([]);

  //Application state
  const[snack, setSnack] = useState(null);
  const[loading, setLoading] = useState(true);
  const[ModalArray, setModals] = useState(null);
  const[sectionTitle, setSection] = useState("Home");
  const[filter, setFilter] = useState({
    sort_by: "rate",
    filter_by: {
      titles : ["all"],
      rating: 1,
      location: "New York",
      distance: 1, //Miles
      union: 2
    }
  });

  // User interactions
  const[favorites, setFavorites] = useState(favoriteArray);
  const[hidden, setHidden] = useState([4]);
  const[conversations, setConversations] = useState(sampleConvs);
  const[savedJobs, setSavedJobs] = useState([]);
  const [dailyApplications, setDailyApplications] = useState(5);

  let history = useHistory();



  const getJobsFromDatabase = ()=>{
    if(firebase.auth().currentUser === null){return false;}
    //console.log("Getting jobs from database");
    let db = firebase.firestore();
    db.collection('jobs').get().then(docs=>{
    let newJobs = []
      docs.forEach(doc=>{
        newJobs.push(doc.data());
      });
     // console.log(newJobs);
      //Uncomment when we're ready to go live
      setJobs([...jobs, ...newJobs]);
    });
  }

  const getPostsFromDatabase = ()=>{
    if(firebase.auth().currentUser === null){return false;}
    console.log("Getting posts from database");
    let db = firebase.firestore();
    db.collection('posts').get().then(docs=>{
    let newPosts = []
      docs.forEach(doc=>{
        newPosts.push(doc.data());
      });
      updatePosts([...newPosts.reverse(), ...posts]);
    });
  }

const getApplicationsFromDatabase = ()=>{
  if(firebase.auth().currentUser === null){return false;}
 // console.log("Getting Applications from database", firebase.auth().currentUser.uid);
  let db = firebase.firestore();

  if(user.type === "worker"){
  db.collection('applications').where('uid', '==', firebase.auth().currentUser.uid).get().then(docs=>{
   let newApplications = [];
    docs.forEach(doc=>{
      newApplications.push(doc.data());
      //console.log(doc.data());
    });
    //Uncomment when we're ready to go live
    updateApplications([...newApplications]);
  });
}else if(user.type === "employer"){
  db.collection('applications').where('owner_id', '==', firebase.auth().currentUser.uid).get().then(docs=>{
    let newApplications = [];
     docs.forEach(doc=>{
       newApplications.push(doc.data());
       //console.log(doc.data());
     });
     //Uncomment when we're ready to go live
     updateApplications([...newApplications]);
   });
}
}



const getReviewsFromDatabase = ()=>{
  if(firebase.auth().currentUser === null){return false;}
 // console.log("Getting jobs from database");
  let db = firebase.firestore();
  db.collection('jobs').get().then(docs=>{
   let newJobs = []
    docs.forEach(doc=>{
      newJobs.push(doc.data());
    });
    //Uncomment when we're ready to go live
    setJobs([...jobs, ...newJobs]);
  });
}

const getUser = (user, callback) =>{
      if(firebase.auth().currentUser === null){
        return;
      }
      let db = firebase.firestore();
      db.collection("users").doc(user.uid).get()
      .then((docRef) => {
       // console.log("Document written with ID: ", docRef);
        if(callback){
          callback(docRef.data());
        }
      })
      .catch(error=>{
        console.log("Error");
        addModal({title:"User Creation Failed", content:"", type:"alert"})
      });
    };

    const updateUser = (user, callback) =>{
       if(firebase.auth().currentUser === null){
        return;
      }else{
        user.uid = firebase.auth().currentUser.uid;
        delete user.password;
      }
      let db = firebase.firestore();
      db.collection("users").doc(user.uid).set({...user},{merge:true})
      .then((docRef) => {
      //  console.log("Document written with ID: ", docRef);
        if(callback){
          callback(docRef);
        }
      })
      .catch(error=>{
        console.log("Error");
         addModal({title:"User Update", content:"", type:"alert"})
      });
    };

    const addApplicationToDatabase = (application, callback) =>{
       if(firebase.auth().currentUser === null){
        return;
      }else{
       
      }
      let db = firebase.firestore();

      let newDocRef = db.collection("applications").doc();
      newDocRef.set({...application, application_id: newDocRef.id}).then((docRef) => {
     //   console.log("Document written with ID: ", docRef);

        let userRef = db.collection("users").doc(application.user_id);
        userRef.update({applications: firebase.firestore.FieldValue.addUnion(application)}).then(()=>{

        });

        let ownerRef = db.collection("users").doc(application.company_id);
        userRef.update({applications: firebase.firestore.FieldValue.addUnion(application)}).then(()=>{
          
        });

        if(callback){
          callback(docRef);
        }
      })
      .catch(error=>{
        console.log("Error");
        addModal(<Confirm title="Something failed">Couldn't post to database.</Confirm>)
      });
    };

    //TODO: Create options modal and implement through addModal method
const createOptionsModal = (options, onSelect, config) =>{
  let optionItems = options.map(option =>{
    if(config && config.singleValue){return <Flex style={{padding:"16px 0"}} className="option-item"> <Label for={option.id}><Radiobutton name="confirm-option" value={option.name} id={option.id} key={option.name+option.id}/>{option.name}</Label></Flex>}
    else{return <Flex style={{padding:"16px 0"}} className="option-item"><Label for={option.name}><Checkbox id={option.name} name={option.name} onChange={value=>{}} selected={false}/> {option.name}</Label></Flex>}
  });

  //Inserts a modal component into the ModalProvider 
  addModal(<Confirm key={Math.random()} isOpen={true}  title={config && config.title ? config.title : null} confirmLabel="SELECT" onConfirm={(value)=>{
    if(value){
      let selectOptions = Array.from(document.querySelectorAll("#select-options input[type='checkbox'], #select-options input[type='radio']"));
      let selectedOptions = selectOptions.filter(optionItem =>{
        return optionItem.checked ? true : false;
      });
      let selectedData = selectedOptions.map(optionItem=>{
        return {name:optionItem.name, id:optionItem.id}
      })
      onSelect(selectedData);
    }
 }} timestamp={Date.now()} style={{minHeight:"calc(100% - 150px)"}}>
 <Box id="select-options">{optionItems}</Box>
 </Confirm>);
 }


 //TODO: Breakout this logic
const contactFunction = (job) =>{

  // Check if the user is not elite, and has enough credits to apply
  if(dailyApplications <= 0){
    return;
  }

  if(user && user.username === "johndoe"){
   addModal(<Confirm key={Math.random()} isOpen={true} title={<>Contact is easy!<br/>Just sign in or register...</>} confirmButton={<div></div>} cancelLabel="CANCEL">
   <Button knockedOut style={{borderColor:"#ffd900", fontSize:"16px", margin:"0 0 16px 0"}} onClick={()=>{history.push("/sign-in")}}>Sign In</Button>
   <Button knockedOut style={{borderColor:"#ffd900", fontSize:"16px"}} onClick={()=>{history.push("/register")}}>Register</Button>
   </Confirm>);
   return false;
 }

  let jobCompany = companies.filter(company=>{if(company.name == job.company){return true}})[0];
  let companyLogo = null;
  if(jobCompany){companyLogo = jobCompany.logo}

  let userId = job.user_id || job.uid;
  let userObject = null;
  if(userId){
    userObject = users.filter((user)=>{return user.user_id == userId ? true : false})[0];
  }

  let TitleImage = userId ? <UserPhoto user={userObject} className="user-image" style={{margin:"0 auto", width:"70px", height:"70px"}}/> : <Image className="company-image" style={{backgroundImage:"url('"+companyLogo+"')", margin:"0 auto"}}/>
  let LocationComponent = <Location style={userId ? {fontSize:"20px", fontWeight:"bold"} : {}}>{userId ? (job.first_name+" "+job.last_name) : job.location}</Location>  ;
 addModal(<Confirm key={Math.random()} isOpen={true} onConfirm={(value)=>{
      if(value){
          let message = document.querySelector("#contact-message").value;

          // Decrement daily applications
          setDailyApplications(dailyApplications - 1);


          //Quick & dirty notification bubble, very inconvenient but easy
          let notificationBubble = document.createElement("div");
          notificationBubble.className = "notification-bubble";
          notificationBubble.style = `border-radius:50%;
              color:white;
              background:red;
              position:absolute;
              top:8px;
              right:8px;
              width:16px;
              height:16px;
              display:flex;
              align-items:center;
              justify-content:center;
              z-index:1;`;

          notificationBubble.textContent = conversations ? conversations.filter(conv =>{return conv.unread ? true : false}).length : '';

          document.querySelector("[href='/chat']").appendChild(notificationBubble);

           // Add job to applications list
          let applicationsArray = [...Applications];

          let newApplication = {
            job_id: job.job_id,
            company_id: job.company_id || '',
            date: new Date(),
            membership: user.membership,
            viewed: false,
            rate: job.rate,
            status: 0,
            uid: user.uid,
            user_id: user.uid,

            conversation: [{
                from: user.uid,
                to: job.owner_id ? job.owner_id : job.company,
                type: 'text',
                message: message,
                name: job.type === "worker" ? (job.first_name+" "+job.last_name) : job.company,
                date: new Date().toString(),
                unread: true,
                title: "Job Application",
              }]
          }

          applicationsArray.push(newApplication);
          updateApplications(applicationsArray);
          addApplicationToDatabase(newApplication);

          addModal(<Confirm title="Application sent" isOpen={true} cancelButton={<div></div>}></Confirm>)
      }
}} timestamp={Date.now()} style={{minHeight:"calc(100% - 150px)"}} title={
    <div style={{textAlign:"center"}}>

    {TitleImage} 

    <ModalTitle style={userId ? {fontWeight:"400"} : {}}>{job.title}</ModalTitle>   
    <Location style={userId ? {fontSize:"20px", fontWeight:"bold", color:"#000"} : {}}>{userId ? (job.first_name+" "+job.last_name) : job.location}</Location> 
    <Score score={job.rating}/>
    </div>
}>



  <StyledLabel>Message</StyledLabel><StyledTextarea id="contact-message"></StyledTextarea></Confirm>);

  //let history = useHistory();

}

  //Social features

  

  const likePost = (post) => {

  }

  const unlikePost = (post) => {

  }

  const followUser = (post) => {

  }

  const unfollowUser = (post) => {
 
  }

  const addCommentToPost = (post) => {

  }

  const deletePostComment = (post) => {

  }

  const editPost = (post) => {
  
  }

  const addPost = (post) => {
    
     let newPostsArray = [...posts];
     newPostsArray.unshift(post);
     updatePosts(newPostsArray);
     setTimeout(()=>{
       updatePosts(newPostsArray);
     },100);

   }


  const addJob = (job) => {
   // addJobToDatabase(job);
    
    let newJobsArray = jobs;
    newJobsArray.unshift(job);
    setJobs(newJobsArray);
    setTimeout(()=>{
      setJobs(newJobsArray);
    },100);

    addJobToDatabase(job);
  }

  const addJobToDatabase = (job,callback) =>{
      if(firebase.auth().currentUser === null){
        return;
      }else{
       job.user_id = firebase.auth().currentUser.uid
      }
      let db = firebase.firestore();
      let docRef = db.collection("jobs").doc();
      docRef.set({...job, job_id : docRef.id, type:"1"},{merge:true})
      .then((docRef) => {
      //  console.log("Document written with ID: ", docRef);
        if(callback){
          callback(docRef);
        }
        addModal(<Confirm title="Your job was posted" isOpen={true} confirmLabel="CONTINUE" cancelButton={<div></div>}><p onClick={()=>{history.push("/job"+docRef.id)}}>View now:</p></Confirm>);
      })
      .catch(error=>{
        console.log("Error");
        addModal(<Confirm title="Something failed">Couldn't post to database.</Confirm>)
      });
  }

 
  

  const deleteJob = (job) => {
 
  }


  const saveJob = function(job_id){
    let favoriteJobs = [...favorites];
    favoriteJobs = favoriteJobs.filter(job =>{
        return job === job_id ? false : true;
    });
    favoriteJobs.push(job_id);
    setFavorites(favoriteJobs);
    setUser({...user, favorites: favoriteJobs});
    updateUser({...user, favorites: favoriteJobs});
}

const unSaveJob = function(job_id){
    let favoriteJobs = [...favorites];

    favoriteJobs = favoriteJobs.filter(job =>{
        return job === job_id ? false : true;
    });

    //console.log(job_id, favoriteJobs, favorites);
    setFavorites(favoriteJobs);
    setUser({...user, favorites: favoriteJobs});
    updateUser({...user, favorites: favoriteJobs});
}

  const addHidden = (jobId) => {
    let hiddenArray = [...hidden];

    hiddenArray = hiddenArray.filter(job_id=>{
      return job_id === jobId ? false : true;
    });

    hiddenArray.unshift(jobId);
    setHidden(hiddenArray);
    setUser({...user, hidden: hiddenArray});
    updateUser({...user, hidden: hiddenArray});
  }

  const removeHidden = (jobId) => {
    let hiddenArray = [...hidden];
    
    hiddenArray = hiddenArray.filter(job_id=>{
      return job_id === jobId ? false : true;
    });

    setHidden(hiddenArray);
    setUser({...user, hidden: hiddenArray});
    updateUser({...user, hidden: hiddenArray});
  }

  const addModal = (modal) => {
    if(modal === null){setModals([]); return;}
    setModals({...modal, key:Math.random()});
  }

  const addApplication = function(application){
      let applications = [...Applications];
      applications.push(application);
      updateApplications(applications);
      addApplicationToDatabase(application);
  }

  const changeApplicationStatus = function(id, status){
    let applications = [...Applications];
    let newApplications = applications.map(a=> {
      if(a.id === id){
        a.status = status;
      }
      return a;
    });
    //let filteredApplication = applications.filter(a=>a.id === id);
    updateApplications(newApplications);
    updateApplicationDatabase({application_id: id, status : status});
  }

  const updateApplicationDatabase = (application, callback) =>{
    if(firebase.auth().currentUser === null){
      return;
    }else{
     
    }
    let db = firebase.firestore();
    db.collection("applications").doc(application.application_id).set({...application},{merge:true})
    .then((docRef) => {
    //  console.log("Document written with ID: ", docRef);
      if(callback){
        callback(docRef);
      }
    })
    .catch(error=>{
      console.log("Error");
      addModal(<Confirm title="Something failed">Couldn't post to database.</Confirm>)
    });
  }

  let path = window.location.pathname;

  if(savedJobs.length === 0 && favorites.length > 0){
    let favoriteJobs = Jobs.filter((job)=>{
      if(favorites.includes(job.id)){
        return true;
      }
    });

    setSavedJobs(favoriteJobs);
  };

  useEffect(()=>{
    getJobsFromDatabase();
    getPostsFromDatabase();
    getApplicationsFromDatabase();

    let hhData = window.localStorage.getItem("hh");
    // if(hhData !== null){
    //   hhData = JSON.parse(hhData);
    
    //   setCompanies(hhData.companies);
    //   setFilter(hhData.filter);
    //   setJobs(hhData.jobs);
    //   setUsers(hhData.users);
    //  // setUser(hhData.user);
    //   setLoading(false);
    //   return;
    // }

  
  },[user]);


  useEffect(()=>{
    let conversations = Applications.map(a=>{return a.conversation});
     setConversations(conversations);
    
  }, [Applications]);

  /* Hack to preload all images and prevent jumps/content shift*/
  useEffect(()=>{
    fetch("/asset-manifest.json").then(data=> {return data.json()}).then(json=>{
   
    Object.keys(json.files).forEach(fileKey=>{
        let file = json.files[fileKey];
        let preloadLink = document.createElement("link")
        preloadLink.setAttribute("href", file);
        preloadLink.setAttribute("rel", "preload");
        preloadLink.setAttribute("as", "image");
        window.document.head.appendChild(preloadLink);
    })
});
},[])

  useEffect(()=>{
      

   
     if(loading === false){return}


     //Move this to separate functions and only receive back promise with json
      if(jobs.length === 0 && firebase.auth().currentUser === null){
        setLoading(true);

        let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1_rBgk25LqqcofswYB6p_52cfD6NFargu-Dbok4r7MWI/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
        fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{
    
        let newJobs = json.values.slice(1,20).map((row, index) =>{
          let latitude = parseFloat(row[6]);
          let longitude = parseFloat(row[7]);
         
          return {
            job_id : index,
            type : row[0],
            date : row[1],
            title : row[2],
            level : row[3],
            rate : row[4],
            location : row[5],
            lat : latitude,
            lng : longitude,
            description : row[8],
            company : row[9],
            rating : row[10],
            distance : row[11],
            logo : row[12]
          }
        }); 
        setJobs([...jobs, ...newJobs]);
        updateApplications([{...newJobs[1], status:0},{...newJobs[3], status:1},{...newJobs[7], status:2}]);
      //  setLoading(false);
        });
      }else if(firebase.auth().currentUser){
       //.then(jobs=>{console.log(jobs)});
      }

      if(companies.length === 0){
        setLoading(true);
        let v3EndPoint = "";
        let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1fJnrbL-MausUyUxI0N0QeSk6mZsP-PWwQtkRHkyWjHE/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
        fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{
        

         // let rows = _.chunk(json.feed.entry, 10);
         // rows = rows.slice(1,25);
      
          let newCompanies = json.values.slice(1).map((row, index) =>{
           
            return {
              id : index,
              name : row[0],
              contact : row[1],
              photo : row[2],
              logo : "/images/company/logo/"+row[0]+".png",
              location : row[4],
              description : row[5],
              ratings : row[6],
              reviews : row[7],
              image : "/images/company/logo/"+row[0]+".png",
              friendly_name : row[9],
            }
          }); 
          setCompanies(newCompanies);
     
          });
      }

      if(users.length === 0){
        setLoading(true);
        let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1v4_e6rdmVmjR4gQd6Oa4s3813od8KeTNelx69tqYlHE/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
        fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{
        
        
         // let rows = _.chunk(json.feed.entry, 21);
          //rows = rows.slice(1,51);
      
          let newUsers = json.values.slice(1).map((row, index) =>{
       
            return {
              user_id : index,
              type : row[0],
              first_name : row[1],
              last_name : row[2],
              username : row[3],
              email : row[4],
              phone : row[5],
              title : row[6],
              level : row[7],
              membership : row[8],
              union_id : row[9],
              location : row[10],
              work_area : row[11],
              image : "/images/user/"+row[3]+".jpeg",
              rate : row[13],
              rating : row[14],
              licenses : row[15],
              certificates : row[16],
              experience : row[17],
              about : row[18],
              lat : row[19],
              lng : row[20],
            }
          });
    
          setUsers(newUsers);
  
          });
        }
      
        if(posts.length === 0){
          setLoading(true);
          let v4EndPoint = "https://sheets.googleapis.com/v4/spreadsheets/1WA9jcixKeVX5GeB7B4Z0XwRUKjZwf2m2-07fLJv-Zyc/values/Sheet1?key=AIzaSyAqXfr9Ibvt1SMD478sCZcbvgsL4KRXViw";
          fetch(v4EndPoint).then(result =>{return result.json()}).then(json =>{

         // let rows = _.chunk(json.feed.entry, 5);
        //  rows = rows.slice(1,26);
      
          let newPosts = json.values.slice(1).map((row, index) =>{

            let user = users;
          
            return {
              post_id : index,
              first_name : row[0],
              last_name : row[1],
              photo : row[2],
              image : "/images/posts/"+index+".jpeg",
              caption : row[4],
            }
          }); 
          updatePosts(newPosts);
          });
        }

         
        window.hh = {
          jobs: jobs,
          user: user,
          users: users,
          companies: companies,
          filter: filter,
          conversations: conversations,
          applications: Applications,
          posts: posts
        }

        //window.localStorage.setItem("hh", JSON.stringify(window.hh));
       
  });

  

 
  // This should be internal state
  let favoriteJobs = [];

  if(jobs !== null){
     favoriteJobs = jobs.filter((job)=>{
      if(favorites.includes(job.job_id)){ return true;}
    })
  
    favoriteJobs = favoriteJobs.map(job=>{
      job.favoriteStatus = true;
      return job;
    });
    
  }

  let location = window.location;
  //let favorites = user.favorites;
  const chatNotifications = conversations ? conversations.filter(conversation =>{if(conversation && conversation.unread === true){return true}}).length : '';
 
   try{
  return (
    <Router>
    
    {/* Just a test */}
    <link rel="preload" href={employerImage} as="image"/>
    <link rel="preload" href={profesionalImage} as="image"/>

    <ThemeProvider theme={theme}>
    {(window.innerWidth > 550) ? <DeviceFrame/> : null}
    <Flex className="App" flexDirection="column">
    <Loading loading={loading}/>
    <Snackbar snack={snack} setSnack={setSnack}/>
    <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
        <div>
          <FirebaseAuthConsumer>
            {({ isSignedIn }) => {
            //  setLoading(false);
              //FIRST TIME LOG-IN 
              if(isSignedIn === true && user !== null && typeof(user.uid) === "undefined"){
                  let db = firebase.firestore();
                  let uid = firebase.auth().currentUser.uid;
                  var docRef = db.collection("users").doc(uid);
                  docRef.get().then(docs=>{
                    if (docs.exists) {
                      let userObject = docs.data();
                        //userObject.uid = uid;

                        if(userObject.favorites && typeof user.favorites === "undefined"){setFavorites(userObject.favorites);}
                        if(userObject.hidden && typeof user.hidden === "undefined"){setHidden(userObject.hidden);}

                        setUser({...user, ...userObject});

                        
                        setTimeout(()=>{setLoading(false)}, 1000);
                                                
                    }else {
                      // doc.data() will be undefined in this case
                      console.log("No such document!");
                      setTimeout(()=>{setLoading(false)}, 1000);
                  }
                      });
                   
                    
              }
              if (isSignedIn === true && user && user.phone && window.location.pathname.split("/")[1] !== "register") {  
                  setTimeout(()=>{setLoading(false)}, 1000);
                  return <Redirect to="/jobs"/>;
              } else if(location.pathname !== "/register/congrats"){
                //return <Redirect to="/"/>;
                setTimeout(()=>{setLoading(false)}, 1000);
              }

              if(isSignedIn === false){
                //Go back to home or stay in /job/:jobId and wait
                //Start loading content
                return <Redirect to="/"/>;
              }

             
            }}
          </FirebaseAuthConsumer>
        </div>
      </FirebaseAuthProvider>

     

 
    <Switch>
          <Route render={(data)=>{
            //These should live in a general routes object
            if(["/sign-in", "/feed","/chat","/me","/me/jobs","/me/saved","/me/contacts","/jobs","/job","/menu","/menu/edit-profile","/menu/notifications","/menu/privacy","/menu/feedback","/menu","/jobs/filter","/jobs/search", "/worker", "/camera/post"].includes(data.location.pathname) || data.location.pathname.split("/")[1] === "job" || data.location.pathname.split("/")[1] === "worker" || data.location.pathname.split("/")[1] === "chat"){
              return <Nav sectionTitle={sectionTitle} setFilter={setFilter} filter={filter} saveJob={saveJob}  unSave={unSaveJob} setSnack={setSnack}/>
            }
          }}/>
    </Switch>
     
     <ModalProvider>
       <Modal isOpen={false}/>
       {ModalArray}
     </ModalProvider>
   
      <Header className="App-header" primary flexDirection="column" flexGrow={1} justifyContent="space-between" overflow="auto">

      <TransitionGroup style={{height:"100%", position:"relative"}}>
        <CSSTransition
          timeout={100}
          classNames='fade'
          key={location}
        >
      <Switch location={location}>
          <Route path="/menu">
            <MenuView setSection={setSection} setUser={setUser} user={user} addModal={addModal} updateUser={updateUser}/>
          </Route>

          <Route exact path="/jobs">
            <JobsFeed hidden={hidden} removeHidden={removeHidden} addHidden={addHidden} setSnack={setSnack} companies={companies} user={user} users={users} favorites={favorites} setSection={setSection}  jobs={jobs} filter={filter} savedJobs={savedJobs} setSavedJobs={setSavedJobs} setFavorites={setFavorites} addApplication={addApplication} updateApplications={updateApplications} addModal={addModal} setConversations={setConversations} conversations={conversations} contact={contactFunction} saveJob={saveJob} unSaveJob={unSaveJob}  setFilter={setFilter}/>
          </Route>

          <Route exact path="/jobs/filter">
            <Filters setSection={setSection} setFilter={setFilter} filter={filter}/>
          </Route>

          <Route exact path="/jobs/search">
            <Search  setSection={setSection} filter={filter} setFilter={setFilter} user={user} jobs={jobs} users={users} companies={companies}/>
          </Route>

          <Route path="/jobs/new">
            <PostJob setSection={setSection} createOptionsModal={createOptionsModal} addJob={addJob} addModal={addModal} user={user}/>
          </Route>

          <Route path="/post/create">
            <CreatePost  setSection={setSection} user={user} addPost={addPost}/>
          </Route>

          <Route path="/me">
            <Me  setSnack={setSnack} user={user} addHidden={addHidden} hidden={hidden} applications={Applications} jobs={jobs} companies={companies} users={users} setSection={setSection} favorites={favorites} setSavedJobs={setSavedJobs} addApplication={addApplication} updateApplications={updateApplications} contact={contactFunction} saveJob={saveJob} unSaveJob={unSaveJob} removeHidden={removeHidden} addHidden={addHidden}  setSnack={setSnack}  addModal={addModal} dailyApplications={dailyApplications}/>
          </Route>

          <Route path="/job/:jobId">
            <JobDetail users={users} companies={companies} user={user} applications={Applications} jobs={jobs} setSection={setSection} favorites={favorites} setSavedJobs={setSavedJobs} addApplication={addApplication} updateApplications={updateApplications} addModal={addModal}  setConversations={setConversations} conversations={conversations} contact={contactFunction} saveJob={saveJob} unSaveJob={unSaveJob}  favorites={favorites} />
          </Route>

          <Route path="/worker/:userId">
            <WorkerDetail users={users} companies={companies} user={user} applications={Applications} jobs={jobs} setSection={setSection} favoriteJobs={favoriteJobs} favorites={favorites} setSavedJobs={setSavedJobs} addApplication={addApplication} updateApplications={updateApplications} addModal={addModal}  setConversations={setConversations} conversations={conversations} contact={contactFunction}/>
          </Route>

          <Route path="/sign-in">
            <SignIn setSection={setSection} addModal={addModal} setLoading={setLoading} setUser={setUser}/>
          </Route>

          <Route path="/register">
            <Registration setLoading={setLoading} setSection={setSection} setFilter={setFilter} user={user} setUser={setUser} addModal={addModal}  guestUser={dummyUser} createOptionsModal={createOptionsModal}/>
          </Route>

          <Route path="/feed">
            <Feed users={users} setSnack={setSnack} posts={posts} user={user} setSection={setSection} />
          </Route>
            

          <Route exact path="/chat">
            <ChatList companies={companies} users={users} user={user} setConversations={setConversations} conversations={conversations} setSection={setSection}/>
          </Route>
          <Route path="/chat/:chatId">
            <ChatConversation applications={Applications} setSection={setSection} user={user} conversations={conversations}  companies={companies}/>
          </Route>
            

          <Route exact path="/">
            <Home setSection={setSection} setUser={setUser} guestUser={dummyUser}/>
          </Route>

          {/* <Route> <Redirect to="/"/></Route> */}
         
         
      </Switch>
      </CSSTransition>
      </TransitionGroup>

        
      </Header>
      <Switch>
      <Route path={["/","/register"]} render={()=>{
        if(path !=="/" && !path.includes("/register")  && !path.includes("/sign-in")){
          return(<Menu key="menu" className={path !=="/" && !path.includes("/register")  && !path.includes("/sign-in") ? "toggled" : ""} chatNotifications={chatNotifications}/>);
        }else{
          return null;
        }
      }}/>   
      </Switch>
      </Flex>
      </ThemeProvider>
      </Router>
     
  );}catch(e){
    console.error(e);
    return "App crashed"
  }
}

export default App;
