import React , {useState, useEffect} from 'react';
import {Box, Flex} from 'rebass';
import CameraFeedItem from '../components/CameraFeedItem.js';
import firebase from 'firebase';

import CommentModal from '../components/CommentModal.js';

import ShareMenu from '../components/ShareMenu.js';

export default function(props){
    props.setSection("Feed");

    const [commentsModalOpen, setCommentModalOpen] = React.useState(false);

    // //Image load test
    // let storage = firebase.storage();
    // let pathReference = storage.ref('Arturo-Mena.png');
    // let arturo = pathReference.getDownloadURL()
    // .then((url) => {
    //   console.log(url)
    //   return url
    // });

    const contentFeed = props.posts.map(post=>{

        let userObject = props.users ? props.users.filter(user=>{return (user.first_name === post.first_name && user.last_name === post.last_name) ? true : false})[0] : props.user;

        return <CameraFeedItem user={userObject} {... post} setCommentModalOpen={setCommentModalOpen}/>
      });

      return(<Flex flexDirection="column">
        {commentsModalOpen && <CommentModal>
          
        </CommentModal>}
        {contentFeed}
  </Flex>);
   
}
  