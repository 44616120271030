import React , {useState, useEffect} from 'react';
import {Box, Flex} from 'rebass';
import Button from '../components/Button.js'
import styled from 'styled-components';

import firebase from 'firebase';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Link,
  useRouteMatch,
  useLocation
} from "react-router-dom";

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

const StyledTextarea = styled.textarea`
color: #adadae;
font-size:16px;
border: none;
flex-grow:1;
padding: 0 0 0 8px;
font-family: Roboto, sans-serif;
resize: none;
&:focus{border:none; outline:none;}
`;


export default function CameraView(props){
  props.setSection("Camera");
    const videoNode = React.createRef();

    const [currentCapture, setCapture] = useState("");
    const [facingMode, setFacing] = useState("user");
    
    const history = useHistory();

    props.setSection("Camera");

    const uploadImage = (data) =>{

      let ref = firebase.storage().ref();
      let fileRef = ref.child('posts/'+firebase.auth().currentUser.uid+"/"+Date.now());
    
        return fileRef.putString(data, 'data_url');
    };
    
    const addPostToDatabase = (post,callback) =>{
      if(firebase.auth().currentUser === null){
        return;
      }else{
      
      }
      let db = firebase.firestore();
      let docRef = db.collection("posts").doc();

      db.collection("posts").doc(docRef.id).set({...post, post_id: docRef.id},{merge:true})
      .then((postRef) => {
        console.log("Document written with ID: ", postRef.id);
    
        
    
        if(callback){
          callback(docRef);
        }
      })
      .catch(error=>{
        console.log("Error",error);
      });

      uploadImage(currentCapture).then((imageRef)=>{
        imageRef.ref.getDownloadURL().then((url)=>{
          db.collection("posts").doc(docRef.id).set({image: url}, {merge:true});
        });
      });
    }
    

    useEffect(()=>{
      console.log(videoNode);

      if(window.location.pathname === "/camera"){
        startCamera();
      }
    },[]);
    
    useEffect(()=>{
      console.log(videoNode);

      
       if(window.location.pathname === "/post/capture" && window.stream.active){
        window.stream.getTracks().forEach(function(track) {
          track.stop();
        });
       }else if(window.location.pathname !== "/post/submit" && typeof window.stream === "undefined" || !window.stream.active){
        startCamera();
       }else if(window.stream && window.stream.active && window.location.pathname !== "/post/capture"){
        document.querySelector("video").srcObject = window.stream;
        document.querySelector("video").play();
       }
    })


    const sharePost = (event)=>{
      let captionText = document.querySelector("textarea").value;
        setTimeout(()=>{props.addPost({caption:captionText, user:props.user, image:currentCapture});
        addPostToDatabase({
          user_id: firebase.auth().currentUser.uid,
          image: "",
          caption: captionText,
          likes: [],
          comments: [],
          shares: [],
          user: props.user
        });
      history.push("/feed")
      },0);
       
    }

    const capturePhoto = (event)=>{
        let video = document.querySelector('video');
        video.style.height = "auto";
        let canvas = document.createElement('canvas');
        canvas.style.visibility = "hidden";
        document.body.appendChild(canvas);
        canvas.width = video.getBoundingClientRect().width;
        canvas.height = video.getBoundingClientRect().height;
        let ctx = canvas.getContext('2d');
        //draw image to canvas. scale to target dimensions
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        //convert to desired file format
        let dataURI = canvas.toDataURL('image/png'); // can also use 'image/png';
        console.log(dataURI)
        setCapture(dataURI);
        return;

    }

    const startCamera = (event)=>{
        navigator.mediaDevices.getUserMedia({ audio: false, video: true, facingMode: facingMode })
.then(function(stream) {
  let video = document.querySelector('video');
  window.stream = stream;
  // Older browsers may not have srcObject
  if ('srcObject' in video) {
    video.srcObject = stream;
  } else {
    // Avoid using this in new browsers, as it is going away.
    //video.src = window.URL.createObjectURL(stream);
  }
  video.onloadedmetadata = function(e) {
    video.play();
  };
})
.catch(function(err) {
  console.log(err.name + ": " + err.message);
});

    }
    

    return(<Flex flexDirection="column" style={{position:'relative', height:'100%'}}>
      <Router>
        <Switch>
          <Route exact path="/post/create">
            <Box height={"100%"}><video style={{width:"100%", height:"100%", objectFit:"cover"}} ref={videoNode}/></Box>
            <Button primary onClick={capturePhoto} style={{position:"absolute", bottom:"0", margin:"16px", maxWidth:"calc(100% - 32px)"}}><Link to="/post/capture">New Photo</Link></Button>
            {/* <Button onClick={()=>{setFacing({
      exact: 'environment'
    }); startCamera();}}>S</Button> */}
          </Route>
          <Route path="/post/capture">
                <img src={currentCapture} style={{height: "100%", objectFit: "cover"}}/>
                <Flex style={{position:"absolute", bottom:0, left:0, width:"100%", height:"auto", padding:"16px"}}>
                  <Button knockedOut style={{borderColor:"#ffee00", color:"#fff", marginRight:"16px"}} onClick={()=>{     setCapture("");}}><Link to="/post/capture">Retake</Link></Button>
                  <Button primary><Link to="/post/submit">Use Photo</Link></Button>
                </Flex>
                
          </Route>
          <Route path="/post/submit">
          {props.setSection("New Post")}
          <Flex style={{borderBottom:"1px solid #adadae", padding: "0 0 16px 0", margin:"16px"}}>
            <img src={currentCapture} style={{maxWidth:"40%"}}/>
            <StyledTextarea placeholder="Write a caption"></StyledTextarea>
            </Flex>
            <Button primary onClick={sharePost}>Share</Button>
          </Route>
        </Switch>
      </Router>
        
    </Flex>);
    }