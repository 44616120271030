import React , {useEffect, useState} from 'react';
import {Box, Flex} from 'rebass';
import styled from 'styled-components';
//import {Launcher} from 'react-chat-window';
//import ChatBot from 'react-simple-chatbot';
//import {ChatFeed, Message} from 'react-chat-ui';
import 'react-chat-elements/dist/main.css';
import { MessageList, Input, Button } from 'react-chat-elements'
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import firebase from 'firebase';

const StyledFlex = styled(Flex)`
    width:100%;
    height: 100%;
    flex-direction: column;
    text-align:left;
    padding: 8px;
    overflow: auto;
    
    & .rsc{width:100% !important; height:100%  !important;}

    & .rsc-container{width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column; border-radius:0 !important;}

    & .rsc-content{flex-grow: 1;}

    & .rsc-ts-bot .rsc-ts-bubble{
      font-family:Roboto !important;
      color:#000 !important;
      border-radius:8px !important;
      background:#ffea9f !important; 
      max-width:70% !important;
    }

    & .rsc-ts-user .rsc-ts-bubble{
      font-family:Roboto !important;
      color:#000 !important;
      border-radius:8px !important;
      background:#fff !important; 
      max-width:70% !important;
    }

    & #sc-launcher{
      width:100%; 
      height:100%;
      position:relative;
    }

    & .sc-chat-window{
      position:relative;
    }

    & .sc-user-input--text{text-align:left}

    & .sc-header{background:#fff; color:#000}
    & .sc-header--close-button{filter: invert(1);} 

    /* Reach Chat Elements */
    & .rce-mbox{padding:12px; border-radius:8px; box-shadow:none;}
    & .rce-mbox-text{font-size:15px;}
    & .rce-container-mbox{padding:6px 0; margin:0;}
    & .rce-container-mbox.left,& .rce-container-mbox.right{max-width: 80%;}
    & .rce-container-mbox.left .rce-mbox{background:#ffea9f}
    & .rce-container-mbox.left .rce-mbox-left-notch{fill:#ffea9f}
    & .rce-container-mbox.right{margin-left:auto;}
    & .rce-container-mbox.right .rce-mbox{background:#b8d6ed}
    & .rce-container-mbox.right .rce-mbox-right-notch{fill:#b8d6ed}
    & .rce-mbox-right-notch,& .rce-mbox-left-notch{top:unset; bottom:8px; transform:scaleY(-1)}
    & .rce-mbox-right-notch path, & .rce-mbox-left-notch path,  & .rce-mbox-right-notch{filter:none !important}

    & .day-date .rce-smsg{background:#eeeeee; border-radius:8px; box-shadow:none; color:#000; font-size:15px;}

    & .rce-container-input{border-radius:24px; border: solid 1px #979797; font-family: 'Roboto'; overflow:hidden; flex-grow:1; min-width:fit-content;}
    & .rce-input{padding-left:16px; font-family: 'Roboto';}
`;

const StyledButton = styled(Button)`
    color: #000 !important;
    padding: 0 8px 0 0 !important;
    font-size:20px !important;
    background: #fff !important;
`;

// const ChatLauncher = styled(Launcher)`
 
// `;


// const messageList = [
//   new Message({
//     id: 1,
//     message:"Hello!"
//   }),
//   new Message({
//     id: 0,
//     message:"Sup there!"
//   }),

// ]



// const messageList = [
//   {
//     className:"day-date",
//     position: 'center',
//     type: 'system',
//     text: 'Sun May 1',
// },
//     {
//         className:"left",
//         position: 'left',
//         type: 'text',
//         text: 'Your profile looks like a good fit. Contact us if you’re interested in this position.',
//         date: new Date("5/1/21 5:15 PM"),
//         dateString: "5:15 PM"
//     },
//     {
//       className:"right",
//       position: 'right',
//       type: 'text',
//       text: `Can you please tell me more about this position? How long is it, what is the location?`,
//       date: new Date("5/1/21 5:20 PM"),
//       dateString: "5:20 PM"
//   },
//   {
//     className:"left",
//     position: 'left',
//     type: 'text',
//     text: `It’s a 4 week contract. The location is at 597 Madison Avenue. If you’re interested give me a call at 
//     (917) 555 -1212`,
//     date: new Date("5/1/21 5:34 PM"),
//     dateString: "5:34 PM"
// },
// {
//   className:"day-date",
//   position: 'center',
//   type: 'system',
//   text: 'Sun May 2',
//   date: new Date("5/2/21 5:15 PM"),
// },
// {
//   className:"right",
//   position: 'right',
//   type: 'text',
//   text: `Thanks for meeting me today.
//   I’m available to start immediately.
//   Do you have the contract to sign?`,
//   date: new Date("5/2/21 8:03 AM"),
//   dateString: "8:03 AM"
// }
// ]


export default function ChatConversation(props){
  

  const [messages, setMessages] = useState([]);

  const { chatId } = useParams();

  if(chatId && messages.length === 0){
    let newMessages = props.applications[chatId] ? props.applications[chatId].conversation : [];
    setMessages(newMessages);
  //  readMessagesFromDatabase();
  }

  let sectionTitle = "Chat"; //props.applications ? props.applications[0].title : "Chat";

  props.setSection(sectionTitle);

  const readMessagesFromDatabase = (slug)=>{

    let db = firebase.firestore();
    
    db.collection("applications").doc(props.applications[chatId].application_id).onSnapshot(
      doc=>{
        setMessages(doc.data().conversation);
      }
    );


    // db.collection("messages").orderBy('date').onSnapshot(
    //   docs => { 
    //     let newMessages = [];
    //     docs.forEach(doc=>{
    //       newMessages.push({
    //         className: props.user !== null ? doc.data().user == props.user.username ? "right" : "left" : "left",
    //         position: props.user !== null ? doc.data().user == props.user.username ? "right" : "left" : "left",
    //         type: doc.data().type,
    //         text: doc.data().content,
    //         date: doc.data().date,
    //         author: doc.data().username
    //       });
    //     })
       
    //  // setMessages(newMessages);
    // });

  }
 
  useEffect(
    ()=>{
     // const cleanUp = firebase.firestore().collection("messages").where("id", "==", id).onSnapshot(snapshot => {});
   // return () => cleanUp();

  readMessagesFromDatabase();
    },[])
    
  let properties ={
      hideHeader:true,
      steps:props.conversation,
      userDelay:25,
      botDelay: 500,
      enableMobileAutoFocus:true,
      width:"100%",
      style:{
          width:"100%",
          height:"100%"
      }
  }
  const sendMessageToDatabase = (message) =>{
    let db = firebase.firestore();
    console.log("Sending message to database", message)
    //Add to messages
    // db.collection("messages").add({
    //   user:props.user.username,
    //   content:message.content,
    //   date:new Date(),
    //   type:message.type
    // })
    // .then((docRef) => {
    //   console.log("Document written with ID: ", docRef.id);
    // })
    // .catch((error) => {
    //   console.error("Error adding document: ", error);
    // });

    //Add to application conversation
   let docRef = db.collection("applications").doc(props.applications[chatId].application_id);
  //  docRef.get().then(doc=>{console.log(message, doc.data())
   docRef.update({conversation: firebase.firestore.FieldValue.arrayUnion(message)}).then((docRef) => {
    console.log("Document written with ID: ", docRef.id);
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
  });
  }

  const sendMessage = message =>{
    let application = props.applications[chatId];
    let messageBody = document.querySelector("textarea").value;
    document.querySelector("textarea").value = "";

     setMessages([...messages, {
      from: props.user.uid,
      to: application.owner_id ? application.owner_id : application.company_id,
      type: 'text',
      message: messageBody,
      name: props.user.type === "worker" ? (props.user.first_name+" "+props.user.last_name) : application.company,
      date: new Date().toString(),
      unread: true,
      title: "Job Application",
    }]);

    
    sendMessageToDatabase({
      from: props.user.uid,
      to: application.owner_id ? application.owner_id : application.company_id,
      type: 'text',
      message: messageBody,
      name: props.user.type === "worker" ? (props.user.first_name+" "+props.user.last_name) : application.company,
      date: new Date().toString(),
      unread: true,
      title: "Job Application",
    });
  }

  const messageList = messages.map((message,index)=>{

  if(message.from === firebase.auth().currentUser.uid){
    return {
          position : "left",
          type : "text",
          text : message.message,
          date: new Date(message.date.seconds).getHours(),
          dateString: new Date(message.date.seconds).getHours().toString() + ":" + new Date(message.date.seconds).getMinutes().toString(),
          className: "left"
  };
  }else if(message.to !== firebase.auth().currentUser.uid){
    return {
          position : "right",
          type : "text",
          text : message.message,
          date: new Date(message.date.seconds),
          dateString: new Date(message.date.seconds).getHours().toString() + ":" + new Date(message.date.seconds).getMinutes().toString(),
          className: "right"
  };
  }
  
});



    return(<StyledFlex>
       {/* <ChatFeed
      messages={messageList} // Array: list of message objects
      isTyping={true} // Boolean: is the recipient typing
      hasInputField={false} // Boolean: use our input, or use your own
      showSenderName // show the name of the user who sent the message
      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
      // JSON: Custom bubble styles
      bubbleStyles={
        {
          text: {
            fontSize: 30
          },
          chatbubble: {
            borderRadius: 70,
            padding: 40
          }
        }
      }
    /> */}

{/* <ChatLauncher
        agentProfile={{
          teamName: 'Chat',
          imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
        }}
        onMessageWasSent={(message)=>{
          console.log(message);
          let newMessageList = [...messages, message];
          setMessages(newMessageList);
          sendMessageToDatabase(message);
        }}
        messageList={messages}
        showEmoji
        isOpen
        
      /> */}

<Box id="message-list-container" flexGrow="1" overflow="auto" height="100%">
<MessageList
    className='message-list'
    lockable={false}
    toBottomHeight={'100%'}
    dataSource={messageList} />
    </Box>
    <Flex>
    <StyledButton
          color='white'
          backgroundColor='black'
          text={<i className="fas fa-camera"></i>}
          ><i className="fas fa-camera"></i></StyledButton>
    <Input
    placeholder="Type here..."
    multiline={true}
  
  rightButtons={
    <StyledButton
        onClick={sendMessage}
        color='white'
        backgroundColor='black'
        text={<i class="fas fa-paper-plane"></i>}/>
  }
  />
    
</Flex>
    </StyledFlex>)}