import React, {useState} from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {Flex, Box} from 'rebass';
import UserPhoto from '../components/UserPhoto';
import CompanyHeader from '../components/CompanyHeader';
import ChatConversation from './ChatConversation.js';

const ChatList = styled.div`

`;

const ChatItem = styled(Flex)`
    position:relative;
    padding: 16px;
    text-align:left;
    border-bottom: 1px solid #666;
`;

const ChatDate = styled.div`
    font-weight:400;
    font-size:14px;
    color:#6D7278;
    position:absolute;
    top:16px;
    right:16px;
`;

const ChatTitle= styled.div`font-size:18px; font-weight:bold;`;
const ChatName = styled.div`font-size:16px;`;
const ChatMessage = styled.div`font-size:14px;`;


const StyledUserPhoto = styled(UserPhoto)`
    & .badge{width:32px; top:-8px; left:-8px}
`;

export default (props) =>{
    props.setSection("Chat");

    const history = useHistory();

    const [activeConversation, setConversation] = useState([ {
        id: '0',
        message: 'Welcome to Hiring Hall!',
        trigger: ()=>{
           setTimeout(()=>{  
                document.querySelector(".rsc-input").value = "Testing";
           document.querySelector(".rsc-submit-button").click();
        },1000)
         
            return "1";
        }
      },
      {
        id: '1',
        user:true,
       message:"test",
       end:true,
        validator:()=>{
            document.querySelector(".rsc-input").value = "Testing"
            document.querySelector(".rsc-submit-button").click();
            return true;
        }
      }]);

    //   if(props.conversations.)

    
    
    const conversationList = props.conversations.map((conv, convIndex) =>{
        if(typeof conv === "undefined"){return};
        let conversationCompany ;
        let lastMessage = conv[conv.length -1];
        conv = conv[0];


        //Hack to find company user if we don't have a user, only matching name for now
        if(conv && typeof conv.user === "undefined"){
            console.log(props.companies, conv, props.conversations)
            conversationCompany = props.companies.filter(company=>{return company.name === conv.to ? true : false})[0];
        }

        if(typeof conversationCompany === "undefined"){
            console.log("Couldn't render chat list")
            return null
        }

        //Find user owner of the company's jobs
        let conversationUser = props.users.find(user=>{
            if(conv.to && conversationCompany){
                return conv.to === user.user_id ? true : false;
            }else{
                return (user.first_name+" "+user.last_name) === conversationCompany.contact ? true : false;
            }
        }) || props.user;

        return <ChatItem onClick={(event)=>{
            //Move to const
            let newConversations = props.conversations;
            newConversations[convIndex].unread = false;
            props.setConversations(newConversations);
            let unreadNotifications = props.conversations.filter(conv =>{return conv.unread ? true : false}).length;

          
          if(unreadNotifications === 0 && document.querySelector("[href='/chat'] .notification-bubble")){
            document.querySelector("[href='/chat'] .notification-bubble").remove();
          }else if(document.querySelector("[href='/chat'] .notification-bubble")){
            document.querySelector("[href='/chat'] .notification-bubble").textContent = unreadNotifications;
          }
            
            //Should be job_id instead of index for prod
            history.push("/chat/"+convIndex);
        }}>
            <StyledUserPhoto user={conversationUser} style={{flex: "0 0 70px",
    height: "70px",
    backgroundColor: "#ddd"}}/>
            <Box style={{padding:"0 0 0 16px"}}>
            <ChatTitle>{conv.title}</ChatTitle>
                <ChatDate>{new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(lastMessage.date.seconds)}</ChatDate>
                <ChatName>{conv.name}</ChatName>
                <ChatMessage>{lastMessage.message}</ChatMessage>
            </Box>
        </ChatItem>
    });

    return <ChatList>
        {conversationList}
       
    </ChatList>
}