import React , {useState, useEffect}from 'react';
import {Box} from 'rebass';
import FeedCard from '../components/FeedCard.js';
import styled , {keyframes} from 'styled-components';
import GoogleMapReact from 'google-map-react';
import Locator from '../components/Locator.js';
import {Wayfinder as WayfinderIcon} from '../components/Icons';
import {CloseIcon} from '../components/Icons.js';

const FilterLabel = styled(Box)`
display:flex;
  position:absolute;
  padding: 0;
  top: 8px;
  left: 8px;
  color:#666;
  background:#fff;
  border-radius:30px;
  line-height: 1;
  font-size: 14px;
  align-items: center;
  padding: 16px;
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 50%);
  z-index: 9;

  & img{max-width:100%;}
`;

const CurrentJob = styled(FeedCard)`
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: 16px;
    width: calc(100% - 32px);
`;

const YellowGradient = styled.div`
height: 50px;
background: linear-gradient(to bottom,rgba(255,217,0,0),#ffd900 48%,#ffc600 100%);
width: 100%;
margin-top: -50px;
position: relative;
`;

const UserAction = styled.div`
position:relative;
`;


const UserLocator = styled.div`
  border-radius:50%;
  width:16px;
  height:16px;
  background:#4380fe;
  border:4px solid #fff;
  box-shadow:0 0 6px 3px rgb(0 0 0 / 20%);
`;

const FindingLocation = keyframes`
  from{border-color: #0c84c1;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  to{border-color: #40b9f7;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 2px 0 rgba(0, 0, 0, 0.5);
  }
`;

const FindLocationButton = styled.div`
position:absolute;
top:8px;
right:8px;
z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 6px 4px 4px 6px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);

  & .location-icon{background:url(${WayfinderIcon}) no-repeat center/contain; width: 32px; height:32px;}

  &.toggled{
    border: 2px solid #0c84c1;
    animation: ${FindingLocation} 1s infinite alternate;
  }
`

const expandJob = (event)=>{
  document.querySelector("#jobs-map").style.height = "calc(100vh - 125px)";
  document.querySelector("#feed-box").style.top = "0";
}




Number.prototype.toRad = function() {
  return this * Math.PI / 180;
}

export default function Map(props){

    const[currentJob, setCurrentJob] = useState(null);
  //  const[userLocation, setUserLocation] = useState();
    const[map, setMap] = useState(null);

  //   const offsetCenter = (latlng, offsetx, offsety) =>{

  //     // latlng is the apparent centre-point
  //     // offsetx is the distance you want that point to move to the right, in pixels
  //     // offsety is the distance you want that point to move upwards, in pixels
  //     // offset can be negative
  //     // offsetx and offsety are both optional
  
  //     var scale = Math.pow(2, map.getZoom());
  
  //     var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
  //     var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);
  
  //     var worldCoordinateNewCenter = new google.maps.Point(
  //         worldCoordinateCenter.x - pixelOffset.x,
  //         worldCoordinateCenter.y + pixelOffset.y
  //     );
  
  //     var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
  
  //    return newCenter;
  
  // }

  useEffect(()=>{
    if(map && props.userLocation){ map.setCenter({lat:props.userLocation.lat,lng:props.userLocation.lng});}
  }, props.userLocation);


    let OnZoomChange = ()=>{

      if(document.querySelector("#current-job-card") !== null){
        setCurrentJob(null);
        console.log("reseting map", currentJob);
       
        document.querySelectorAll("#jobs-map .toggled").forEach(locator=>{locator.classList.remove('toggled')})
      }
    };

    //We should move this outside so all components can use the user location
    // const LocateUser = ()=>{
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(position=>{
    //      if(map){ map.setCenter({lat:position.coords.latitude,lng:position.coords.longitude});}
    //       setUserLocation({lat:position.coords.latitude, lng:position.coords.longitude})
    //     });
    //   }
    // }

   // if(props.userLocation == null && !props.detail && props.locationEnabled){LocateUser();}
    //console.log(props.jobs)
    

    
    const locators = props.jobs.map((job)=>{
      if(props.favorites && props.favorites.includes(job.job_id)){return null};
      return(<Locator
      key={job.job_id || job.user_id}
        onClick={(e)=>{
          console.log(job);
          document.querySelectorAll("#jobs-map .toggled").forEach(locator =>{locator.classList.remove("toggled")})
          e.currentTarget.classList.add("toggled");
          e.currentTarget.parentNode.style.zIndex = 9;
          document.querySelector("#jobs-map div").style.display = "block"; 
        
          let company = props.companies.filter(c=>{return(c.name == job.company ? true : false)});
          if(company.length >0){
            job.cardImage = company[0].logo;
          }

         
         
         
          
          setCurrentJob(job); 

   

        
      
     
        //  let currentCardHeight = document.querySelector("#current-job-card").getBoundingClientRect().height; 
          let currentCardHeight = 229.16; 
          let mapHeight = map.getDiv().getBoundingClientRect().height;
          let mapCenter = mapHeight /2;
          let windowAreaHeight = mapHeight - currentCardHeight - 50 - 16;
          let windowAreaCenter = windowAreaHeight /2;
          let offsetY = mapCenter - windowAreaCenter;
        //  let availableCenter = offsetCenter({lat:parseFloat(job.lat),lng:parseFloat(job.lng)}, 0, offsetY );
          map.setCenter({lat:parseFloat(job.lat),lng:parseFloat(job.lng)});
         // map.setCenter(availableCenter);
          map.setZoom(16);
          map.panBy(0,offsetY);

          expandJob();
        
      }}
        lat={parseFloat(job.lat)}
        lng={parseFloat(job.lng)}
        job={job}

      />)
    });

    if(map){
      // map.addListener("center_changed", (e) => {
      //   // 3 seconds after the center of the map has changed, pan back to the
      //   // marker.
      //   window.setTimeout(() => {
      //     setCurrentJob(null);
      //     document.querySelectorAll("#jobs-map .toggled").forEach(locator=>{locator.classList.remove('toggled')})
      //   }, 3000);
      // });
      

      map.addListener("click", (e) => {
        setCurrentJob(null);
        //Reset all toggled locators
        document.querySelectorAll("#jobs-map .toggled").forEach(locator=>{locator.classList.remove('toggled')})
      });

      map.addListener("zoom_changed", OnZoomChange);
      map.addListener("center_changed", OnZoomChange);
      map.addListener("dragstart", OnZoomChange);

    }

   
    

    return(<Box id="jobs-map" width="100%" minHeight="250px" style={{position:"relative", ...props.style}}>
    
            
    <CurrentJob {...currentJob} addHidden={props.addHidden}  addHidden={props.addHidden} addApplication={props.addApplication} contact={props.contact} id="current-job-card"  saveJob={props.saveJob} unSaveJob={props.unSaveJob} removeHidden={props.removeHidden} contact={props.contact} setSnack={props.setSnack} user={props.user} addModal={props.addModal}/>
    {!props.detail ? <FindLocationButton onClick={(e)=>{
      props.setLocationEnabled(true);
      if(map && props.userLocation){ map.setCenter({lat:props.userLocation.lat,lng:props.userLocation.lng});}
     // LocateUser();
      }} className={props.locationEnabled ? "toggled" : ''}><div className="location-icon"></div></FindLocationButton> : null}

    {!props.detail && props.filter && props.filter.filter_by.titles.length === 1 && props.filter.filter_by.titles[0] !== "all" && 
      <FilterLabel>
      {props.filter.filter_by.titles[0]}
      <UserAction variant='nav' to='/jobs' style={{position:"relative", fontSize:"12px", color:"#666", textDecoration:"none", width:"1em", height:"1em", opacity:"0.5", marginLeft:"12px"}}>
              <img src={CloseIcon}  onClick={e=>{
              e.preventDefault(); 
              props.setFilter({...props.filter, filter_by:{titles:["all"]}})
              }}/>
      </UserAction>
      </FilterLabel>}

    <GoogleMapReact
     yesIWantToUseGoogleMapApiInternals
       bootstrapURLKeys={{ key: 'AIzaSyAADsZn62fXC18xoTboT_sFHo6X3bXClH4' }}
       heading={320}
        defaultCenter={(props.detail && props.jobs[0]) ? {lat:parseFloat(props.jobs[0].lat),lng:parseFloat(props.jobs[0].lng)} : {lat:40.7608039, lng:-73.975}}
        defaultZoom={props.detail ? 16 : 15}
        clickableIcons= {false}
 
        onGoogleApiLoaded={({ map, maps }) => {
          
            setMap(map);
            map.setClickableIcons(false);
            window.map = map;
        /*   map.setOptions({styles:[
            {
      featureType: "administrative",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ]}) ;*/
  }}
       options={{
        disableDefaultUI:true,
        rotateControl: true,
        heading:210,
        gestureHandling: "greedy",
       }}
      >
        {locators}
        <UserLocator lat={props.userLocation ? props.userLocation.lat : 0}  lng={props.userLocation ? props.userLocation.lng : 0}/>
      </GoogleMapReact>
      {props.detail ? null : <YellowGradient/>}
      </Box>)
}
