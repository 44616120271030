import React, {useState, useEffect} from 'react';
import firebase from 'firebase';
import {Flex, Box} from 'rebass';
import {Redirect} from 'react-router-dom';
import { Label, Textarea } from '@rebass/forms'
import Button from '../components/Button.js';
import Subheading from '../components/Subheading.js';
import styled from 'styled-components';
import UserHeader from '../components/UserHeader';
import FeedList from '../components/FeedList';
import Map from './Map.js';
import Score from '../components/Score.js';
import Confirm from '../components/Confirm.js';
import UserPhoto from '../john_doe.png';
import EliteBadge from '../elite_badge.svg';
import SampleLogo from '../sample_logo.png';
import LocationIcon from '../icons/location_icon.svg';
import CalendarIcon from '../icons/calendar_icon.svg';
import HeartIcon from '../icons/heart_icon.svg';
import ShareIcon from '../icons/share_icon.svg';

import DescriptionIcon from '../icons/description.svg';
import CarpenterIcon from '../icons/carpenter.svg';
import InfoIcon from '../icons/info.svg';
import {RatingIcon} from '../components/Icons';


import {Link, Switch, Route, useRouteMatch, useParams, useHistory} from 'react-router-dom';

import ApplicationsList from '../components/ApplicationsList';

import CompanyHeader from '../components/CompanyHeader.js';

import Review from '../components/Reviews/Review';
import ReviewsOutline from '../components/Reviews/ReviewsOutline';

const ProfilePhoto = styled(Box)`
position:relative;
width:116px;
height:116px;
border-radius:50%;
margin:0 auto;
background-image: url(${UserPhoto});
`;

const CertifiedBadge = styled(Box)`
    background-image:url(${EliteBadge});
    width:48px;
    height:48px;
    position:absolute;
    top:0;
    left:-24px;
    background-size:contain;
    background-repeat:no-repeat;
`;


const TabbedNavButton = styled(Box)`
    position: relative;
    font-size: 14px;
    color: #000;
   
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
    & a{
        color:#000;
        text-decoration: none;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    &.toggled:after{
        content:"";
        height:5px; 
        width:100%;
        background:#000;
        position:absolute;
        bottom:0;
        left:0;
    }
`;


const Title = styled.div`
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #000;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:2em; margin-right:1em;}
`;



const YellowTitle = styled.div`
     margin: 39px 2px 17px 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #f6bf00;
  text-transform:uppercase;
    display:flex;
    align-items:center;
  & img{width:2em; margin-right:1em;}
`;

const WhiteTitle = styled.div`
     font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding:16px 16px 0;
`;

const ReadMoreButton = styled.div`
     font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffc600;
  padding:8px 16px;
`;

const ReadAllButton = styled.div`
     font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffc600;
  padding:8px 16px;
  text-align: right;
`;

const FeedListStyled = styled(FeedList)`
    
    height:auto;
    background: transparent !important;

    & #feed-box{display:flex; flex-direction:row; align-items:flex-start; padding:16px;}
    & .feed-card{flex:0 0 auto; margin-right:16px; box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12); margin-bottom: 0 !important; min-width:84%; height:230px;}
   
`;




const Image = styled(Box)`width:100px; height:100px; background-repeat:no-repeat; background-size:contain; background-position:center;`;
const ModalTitle = styled(Box)`font-size:24px; text-align:center;`;
const Location = styled(Box)`color:#4a4a4a; font-size:14px; font-weight:400; text-align:center;`;
const StyledTextarea = styled(Textarea)`
    background: #d8d8d8;
    color:#4a4a4a;
    font-size:16px;
    border-radius:0;
    border:0;
    border-bottom: 1px solid #ffc600;
    width:100%;
    height:100%;
    min-height:150px;
    font-family: 'Roboto';
    line-height:1.4
`;

const StyledLabel = styled(Label)`
    color: rgba(0, 0, 0, 0.38);
    padding: 8px;
    background: #d8d8d8;
    margin-bottom:-1px;
    font-size:12px;
`;






export default function Detail(props){

    let Reviews = [];
while(Reviews.length < 50){
    Reviews.push({
        user: props.user,
        date: "2/22/2022",
        score: parseInt(Math.random()*6),
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc luctus dignissim justo aliquet tincidunt. Sed sagittis vestibulum felis quis sodales. Fusce cursus metus nulla, eu suscipit sapien consectetur ac. Morbi porttitor, libero quis vulputate ultricies, lectus dui lacinia arcu, non sagittis lacus eros efficitur neque. Donec hendrerit egestas scelerisque. Vivamus auctor odio at nulla fermentum aliquet. Cras scelerisque tempor rhoncus. Aliquam pharetra neque in lacus tempus, eu dignissim purus convallis.`,
    })
}

    const reviews = Reviews.slice(0,3).map(review=>{
        return <Review {...review} style={{borderBottom:"1px solid rgba(0,0,0,0.12)", padding:"16px 0"}}/>
    });

    const UpgradeBanner = (props.user && props.user.membership !== "elite") ? <Box style={{color:"#f6bf00", fontSize:"14px", background:"black", padding:"16px", textTransform:"uppercase"}}><span style={{fontSize:"24px"}}>5</span> Applications Remaining* <span style={{textDecoration:"underline"}}>JOIN ELITE</span></Box> : null;


    let { jobId } = useParams();
    const [job, setJob] = useState(null)
    let {path, url} = useRouteMatch();
    let history = useHistory();

    useEffect(()=>{
        document.querySelector(".App-header").scrollTop = 0;
        if(job == null || typeof job === "undefined"){
            let newJob = props.jobs.filter(job=>{if(job.job_id === jobId){return true}});
    
            if(newJob.length == 1){
               // setJob(newJob[0]);
                props.setSection(newJob[0].company);
            }
        
            
        }
    });

    const getJobFromDatabase = (jobId)=>{
        console.log("Getting single job from database");
        let db = firebase.firestore();
        let docRef = db.collection("jobs").doc(jobId);
        docRef.get().then(job=>{
            console.log("Got response", job);
            if(job.exists){
                setJob(job.data());
            }else{
                return <Redirect to="/"/>
            }
        }).catch(e=>console.log);
    }

    // if(job === null){
    //     console.log("Job is null");
    //     if(firebase.auth().currentUser === null){
    //         setTimeout(()=>{
    //             if(firebase.auth().currentUser){
    //                 getJobFromDatabase(jobId);
    //                 return;
    //             }
    //             let currentJob = props.jobs.filter(job=>{if(job.job_id.toString() === jobId){return true}})[0];
    //         if(currentJob){
    //             setJob(currentJob);
    //         }else{
    //             return <Redirect to="/"/>
    //         }
    //         },3000);
    //     }else{
    //         getJobFromDatabase(jobId);
    //     }
    // }


   // const [similarJobs , setSimilarJobs] = useState({})

    let jobsToggled = (path == "/me/jobs") ? "toggled" : null;
    let savedToggled = (path == "/me/saved") ? "toggled" : null;
   

   
    if(job === null || typeof job === "undefined"){
        let newJob = props.jobs.filter(job=>{if(job.job_id.toString() === jobId){return true}});
        if(newJob.length === 1){
             setJob(newJob[0]);
       //    job = newJob[0];
            props.setSection(newJob[0].company);
        }
    }
    
    if(job === null || typeof job === "undefined"){return "Loading...."}
  
    if(typeof job.date === "object"){
        let dateObject = new Date(job.date.seconds);
        let dateString = dateObject.getDate()+"/"+dateObject.getMonth()+"/"+dateObject.getFullYear()+"/";
        job.date = dateString;
    }



    let aboutText = "";

    if(job.company){
        let company = props.companies.filter(c=>{return(c.name == job.company ? true : false)});
        if(company.length >0){
            aboutText = company[0].description;
        }
    }

    const similarJobs = props.jobs.filter(jobItem=>{
        if(job && jobItem.title == job.title && jobItem.job_id !== job.job_id){
            return true
        }
    });

    const companyJobs = props.jobs.filter(jobItem=>{
        if(job && jobItem.company === job.company && jobItem.job_id !== job.job_id){
            return true
        }
    });
  
    const jobContact = props.companies.filter(company =>{return company.name == job.company ? true : false})[0].contact;

    const jobContactUser = props.users.filter(user=>{return user.first_name +" "+user.last_name == jobContact ? true : false})[0];

    const jobContactPhoto = props.users.filter(user=>{return user.first_name +" "+user.last_name == jobContact ? true : false})[0].image;

    

    const jobContactRole = "Hiring Manager";

    return(<Box  style={{textAlign:"left"}}>
        
        <Flex flexDirection="column">
        {job && job.company ? <CompanyHeader job={job} companies={props.companies}/> :  <UserHeader user={props.user} style={{padding:"24px 0 8px"}}/>}
         </Flex>

    <Flex style={{padding:"16px"}}>
             <Box style={{textAlign:"left", flex:"0 60%"}}>
                 
              
                 <Box style={{fontSize:"24px", fontWeight:"900", paddingBottom:"16px"}}>{job && job.title}</Box>
             
                
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{job && job.company}</Box>
                 <Box style={{fontSize:"14px", color:"#4a4a4a"}}>{job && job.location}</Box>
                 <Score score={job && job.rating} style={{width:"fit-content", marginTop:"8px"}}/>
             </Box>
             <Flex style={{textAlign:"right", flex:"0 40%"}} flexDirection="column" justifyContent="space-between">
<Box>{job && job.company ?    <Box style={{fontSize:"24px", fontWeight:"bold", paddingBottom:"16px"}}>{job && "$"+job.rate+"/h"}</Box> : <img src={SampleLogo}/>}</Box>
                 <Box>
                     <Box style={{color:"#4a4a4a", fontSize:"12px", display:"flex", alignItems:"center", justifyContent:"flex-end", paddingBottom:"8px"}}><img src={LocationIcon} style={{width:"16px", marginRight:"8px"}}/> {job && job.distance} mi</Box>
                    
                     <Box style={{color:"#4a4a4a", fontSize:"12px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}><img src={CalendarIcon} style={{width:"16px", marginRight:"8px"}}/> {job && job.date}</Box>
                 </Box>
             </Flex>
         </Flex>

         <Flex style={{justifyContent:"space-between", padding:"16px 24px", alignItems:"center", border:"1px solid rgba(0, 0, 0, 0.12)", borderWidth:"1px 0"}}>
         <Box>
            <Box style={{color:"#818181", fontSize:"12px"}}>Posted by</Box>
            <Box style={{color:"#000", fontSize:"21px"}}>{jobContact}</Box>
            <Box style={{color:"#000", fontSize:"16px"}} >{jobContactRole}</Box>

         </Box>
         <Box>
             <img src={jobContactPhoto} style={{borderRadius:"50%", width:"65px", height:"65px", objectFit:"cover"}}></img>
         </Box>
         </Flex>

         <Box>
             <YellowTitle><img src={DescriptionIcon}/> JOB DESCRIPTION</YellowTitle>
            <p  style={{padding:"0 16px", WebkitLineClamp:"5", display:"-webkit-box", WebkitBoxOrient:"vertical", overflow:"hidden"}}>{job && job.description !== "Test" ? job.description : `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc luctus dignissim justo aliquet tincidunt. Sed sagittis vestibulum felis quis sodales. Fusce cursus metus nulla, eu suscipit sapien consectetur ac. Morbi porttitor, libero quis vulputate ultricies, lectus dui lacinia arcu, non sagittis lacus eros efficitur neque. Donec hendrerit egestas scelerisque. Vivamus auctor odio at nulla fermentum aliquet. Cras scelerisque tempor rhoncus. Aliquam pharetra neque in lacus tempus, eu dignissim purus convallis.

Morbi non posuere lectus. Maecenas ultrices ex quis ex viverra pretium. Vivamus vel feugiat arcu, vel tristique sapien. Mauris sed lacinia metus, ac commodo mi. Proin tempor maximus nulla, sit amet congue enim volutpat ac. Integer dignissim turpis eu eleifend sagittis. Etiam sed finibus lacus.

Nam dignissim quis metus a lacinia. Maecenas eget semper leo. Sed tincidunt, erat porttitor rutrum eleifend, urna mauris suscipit leo, a convallis lectus dolor at urna. Maecenas at tincidunt sem. Nam finibus eget nulla vel tincidunt. Cras congue lobortis ante ac interdum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed suscipit tristique metus, vitae maximus justo pellentesque sed. Proin sodales dui eu laoreet lacinia. Donec id mi lorem. Quisque nunc tellus, dignissim eget sem sit amet, cursus gravida tortor.` }</p>
            <ReadMoreButton onClick={event=>{
                if(event.currentTarget.textContent == "Read More"){
                    event.currentTarget.previousElementSibling.style.display = "block";
                    event.currentTarget.textContent = "Read Less";
                }else{
                    event.currentTarget.previousElementSibling.style.display = "-webkit-box";
                    event.currentTarget.textContent = "Read More"
                }
           
            }}>Read More</ReadMoreButton>
         </Box>

   

         <Map id="jobs-map" jobs={job ? [job] : []} style={{pointerEvents:"none", height:"250px"}} detail={true}/>

         <Box>
<Title><img src={InfoIcon}/> {job && job.company ? "ABOUT THIS COMPANY" : "About Me"}</Title>
             <p style={{padding:"0 16px", WebkitLineClamp:"5", display:"-webkit-box", WebkitBoxOrient:"vertical", overflow:"hidden"}}>{aboutText}</p>
            <ReadMoreButton onClick={event=>{
                if(event.currentTarget.textContent == "Read More"){
                    event.currentTarget.previousElementSibling.style.display = "block";
                    event.currentTarget.textContent = "Read Less";
                }else{
                    event.currentTarget.previousElementSibling.style.display = "-webkit-box";
                    event.currentTarget.textContent = "Read More"
                }
           
            }}>Read More</ReadMoreButton>
           
         </Box>



         <Box>
             <YellowTitle><img src={RatingIcon}/> Ratings and Reviews</YellowTitle>
             
             <Box padding={"16px"}>
             <ReviewsOutline reviews={Reviews} /> 

             {reviews}
</Box>
            <ReadAllButton onClick={()=>{history.push("/reviews/#id")}}>See All {reviews.length} Reviews</ReadAllButton>
         </Box>

          
       
         {companyJobs.length > 0 && <Box style={{backgroundColor:"#ffd900"}}>
         <Title style={{letterSpacing:"0", margin:0, fontWeight:"600", textTransform:"unset", padding:"16px 16px 0"}}>More Jobs from {job.company}</Title>
             <FeedListStyled jobs={companyJobs} companies={props.companies}/>
         </Box>}

         {similarJobs.length > 0 && <Box style={{backgroundColor:"#ffd900"}}>
             <Title style={{letterSpacing:"0", margin:0, fontWeight:"600", textTransform:"unset", padding:"16px 16px 0"}}>Similar Jobs</Title>
             <FeedListStyled jobs={similarJobs} companies={props.companies}/>
         </Box>}

         <Box style={{padding:"16px 16px 24px", position:"sticky", bottom:"0"}}>
            <Button primary onClick={()=>{props.contact(job);}}>Apply</Button>
         </Box>
 
    </Box>);
}