import React, {useState} from 'react';

import {Label, Input} from '@rebass/forms';

import loadData from '../loadData.js';
import UserHeader from '../components/UserHeader.js';
import UserPhoto from '../components/UserPhoto.js';

import { Switch as SwitchComponent }  from '@rebass/forms';

export default function EditProfile(props){
    props.setSection("Edit Profile");

    const changeValue = event =>{
        if(event.currentTarget.id == "name-field"){
            props.user.name = event.currentTarget.value;
            props.setUser(props.user);
        }

        if(event.currentTarget.id == "location-field"){
            props.user.location = event.currentTarget.value;
            props.setUser(props.user);
        }

       

        if(event.currentTarget.id == "trade-field"){
            props.user.title = event.currentTarget.value;
            props.setUser(props.user);
        }

        if(event.currentTarget.id == "image-field"){
            loadData(event.currentTarget.files[0], (data)=>{
                props.user.image = data.source;
                props.setUser(props.user);
            });
        }

        if(event.currentTarget.id == "level-field"){
            loadData(event.currentTarget.files[0], (data)=>{
                props.user.image = data.source;
                props.setUser(props.user);
            });
        }

        props.updateUser({...props.user});
    };

   return <div>
       <UserHeader user={props.user}  style={{backgroundImage:"linear-gradient(127deg, #ffee00 12%, #f6bf00 76%)", borderBottom:"solid 1px #666"}}/>
    {/* <UserPhoto image={props.user.image} membership={props.user.membership || "elite"}/> */}
      <Label>Photo</Label>
<Input id="image-field" type="file"  onChange={changeValue}/>

       <Label>Name</Label>
       <Input id="name-field" defaultValue={props.user.name} onChange={changeValue}/>

       <Label>Username</Label>
       <Input id="username-field" defaultValue={props.user.username} onChange={changeValue}/>

       {/* <Label>Password</Label>
       <Input id="password-field" defaultValue={props.user.password} onChange={changeValue}/> */}

       <Label>Email</Label>
       <Input id="email-field" defaultValue={props.user.email} onChange={changeValue}/>

       {/* <Label>membership (admin)</Label>
       <Input id="membership-field" defaultValue={props.user.membership} onChange={changeValue}/> */}

       {/* <Label>Phone</Label>
       <Input id="phone-field" defaultValue={props.user.phone} onChange={changeValue}/> */}

       <Label>Location</Label>
       <Input id="location-field" defaultValue={props.user.location} onChange={changeValue}/>

       <Label>Trade</Label>
       <Input id="trade-field" defaultValue={props.user.trade} onChange={changeValue}/>

       <Label>Level</Label>
       <Input id="level-field" defaultValue={props.user.level} onChange={changeValue}/>


       <Label>Union</Label>
       <SwitchComponent onChange={changeValue} alignSelf={"flex-start"}/>

   </div>;
}
